// hooks/useTagGift.js
import { useMemo } from 'react';
import useGetTagPromotion from 'zustand-lib/useGetTagPromotion';

const useTagGift = (product, sku, productCode) => {
  const { dataByPromoListsDetail, filterTagGift } = useGetTagPromotion();

  return useMemo(() => {
    const isTagGiftFromProduct = product?.isTagGift;
    const isTagGiftFromAPI = filterTagGift(sku, dataByPromoListsDetail, productCode);

    return isTagGiftFromProduct ?? isTagGiftFromAPI;
  }, [product, sku, dataByPromoListsDetail, productCode, filterTagGift]);
};

export default useTagGift;
